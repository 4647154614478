body {
    margin : 0;
    padding: 0;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: left;
}

.text-right {
    text-align: left;
}

ul {
    margin : 0;
    padding: 0;
}
